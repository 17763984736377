import React from 'react';
import './style.css';

import ME from '../../assets/me-about.jpg';
import {MdRadio} from 'react-icons/md';
import {BsNewspaper} from 'react-icons/bs';
import {MdDesignServices} from 'react-icons/md';


const About = () => {
    return (
        <section id="about" className="about">
          <h5>Conóceme</h5>
          <h2>Sobre Mí</h2>
          <div className="container about__container">
            <div className="about__me">
              <div className="about__me-image">
                <img src={ME} alt="Jorge Garcia" />
              </div>
            </div>

            <div className="about__content">
              <div className="about__cards">
                <article className="about__card">
                  <MdRadio className="about__icon" />
                  <h5>Radio</h5>
                  <small>20+ Años.<br/>1600AM</small>
                </article>

                <article className="about__card">
                  <BsNewspaper className="about__icon" />
                  <h5>Periodico</h5>
                  <small>7+ Años.<br/>El Planeta</small>
                </article>

                <article className="about__card">
                  <MdDesignServices className="about__icon" />
                  <h5>Publicidad</h5>
                  <small>20+ Años<br/>316 Graphics</small>
                </article>
              </div>

              <br/>
              <iframe title="carlos" src="https://open.spotify.com/embed/show/4i8JdR04gdCa40E1JHeN2H?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      

              <p>Por medios informativos y de entretenimiento promuevo y reconozco la cultura latina en los Estados Unidos. Participo activamente y soy comentarista en espacios deportivos, tegnologicos y comunitarios <br/> Ademas, con mi empresa, 316 Graphics Studio, genero publicidad, tanto digital como física para dueños de negocios.</p>
              <a className="btn btn-primary" href="#contact">Hablemos!</a>
            </div>
          </div>
        </section>
    )
}

export default About;
