import React from 'react';
import './style.css';

import {FaFacebookF} from 'react-icons/fa';
import {FiInstagram} from 'react-icons/fi';
import {FaLinkedinIn} from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
          <a href="#" className="footer__logo">José Cabrera</a>

          <ul className="permalinks">
            <li><a href="#">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#testimonials">Testimonials</a></li>
            <li><a href="#contact">Contact</a></li>

          </ul>

          <div className="footer__socials">
            <a href="https://www.facebook.com/elpatojocabrera/" target="_blank" rel="noreferrer"><FaFacebookF /></a>
            <a href="https://www.instagram.com/elpatojocabrera/" target="_blank" rel="noreferrer"><FiInstagram /></a>
            <a href="www.linkedin.com/in/elpatojocabrera" target="_blank" rel="noreferrer"><FaLinkedinIn/></a>
          </div>

          <div className="footer__copyright">
            <small>
              &copy; Copyright 2022 José Cabrera. Todos los derechos reservados.
            </small>
          </div>
        </footer>
    )
}

export default Footer;
