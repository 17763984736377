import React from 'react';
import './style.css';

import IMG1 from '../../assets/portfolio1.png';
import IMG2 from '../../assets/portfolio2.png';
import IMG3 from '../../assets/portfolio3.png';
import IMG4 from '../../assets/portfolio4.png';
import IMG5 from '../../assets/portfolio5.png';
import IMG6 from '../../assets/portfolio6.png';

const data = [
{id:1,image:IMG1,title:"Zitis Italian Trattoria",repo:"https://github.com", demo:"https://zitisitaliantrattoria.com"},
{id:2,image:IMG2,title:"First Choice Cleaning",repo:"https://github.com", demo:"https://firstchoicecleaning.net"},
{id:3,image:IMG3,title:"Mateo's Landscaping",repo:"https://github.com", demo:"https://mateosma.com"},
{id:4,image:IMG4,title:"Swift Demolition",repo:"https://github.com", demo:"https://swiftdemolitionma.com"},
{id:5,image:IMG5,title:"Javier's Landscaping",repo:"https://github.com", demo:"https://javierslandscaping.web.app"},
{id:6,image:IMG6,title:"Real Auto Glass",repo:"https://github.com", demo:"https://realautoglassbos.com"},
];

const PortfolioItem = (props) => {
    const {image, title, repo, demo} = props.item;

    return (
      <article className="portfolio__item">
        <div className="portfolio__item-image">
          <img src={image} alt="" />
        </div>
        <h3>{title}</h3>
        <div className="portfolio__item-cta">
          {/* <a className="btn" href={repo}>Github</a> */}
          <a className="btn btn-primary" href={demo} target="_blank">Live Demo</a>
        </div>
      </article>
    );
}

const Portfolio = () => {
    return (
      <section id="portfolio" className="portfolio">
        <h5>Mi trabajo reciente</h5>
        <h2>Portafolio</h2>
        <div className="container portfolio__container">
          {data.map(item => <PortfolioItem key={item.id} item={item}/>)}
        </div>
      </section>
    )
}

export default Portfolio;
