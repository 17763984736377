import React from 'react';
import {BsLinkedin} from 'react-icons/bs';
import {FaFacebook} from 'react-icons/fa';
import {RiInstagramFill} from 'react-icons/ri';

const HeaderSocials = () => {
    return (
        <div className="header__socials">
          <a href="https://www.facebook.com/elpatojocabrera/" tearget="_blank"><FaFacebook/></a>
          <a href="https://www.instagram.com/elpatojocabrera/" tearget="_blank"><RiInstagramFill/></a>
          <a href="https://www.linkedin.com/in/elpatojocabrera" tearget="_blank"><BsLinkedin/></a>
        </div>
    )
}

export default HeaderSocials;
