import React from 'react';
import './style.css';

import CTA from './CTA';
import ME from './../../assets/me.png';
import HeaderSocials from './HeaderSocials';


const Header = () => {
    return (
        <header className="header">
          <div className="container header__container">
            <h5>Hola!! Yo soy</h5>
            <h1>Jose Cabrera</h1>
            <h5 className="text-light">EL PATOJO CABRERA</h5>
            <CTA />
            <HeaderSocials />
            <div className="me">
              <img src={ME} alt="Jose Cabrera" />
            </div>

            <a href="#contact" className='scroll__down'>Scroll Down</a>
          </div>
        </header>
    )
}

export default Header;
