import React from 'react';
import './style.css';

import {MdOutlineEmail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';


const Contact = () => {
    return (
      <section id="contact" className="contact">
        <h5>Hablemos!!</h5>
        <h2>Contactame</h2>
        <div className="container contact__container">
          <div className="contact__options">
            <article className="contact__option">
              <MdOutlineEmail className='contact__option-icon' />
              <h4>Email</h4>
              <h5>jc@316graphicsstudio.com</h5>
              <a href="mailto:jc@316graphicsstudio.com">Send a message</a>
            </article>

            <article className="contact__option">
              <RiMessengerLine className='contact__option-icon' />
              <h4>Messenger</h4>
              <h5>@elpatojo.cabrera</h5>
              <a href="https://m.me/elpatojo.cabrera">Send a message</a>
            </article>

            <article className="contact__option">
              <BsWhatsapp className='contact__option-icon' />
              <h4>WhatsApp</h4>
              <a href="https://api.whatsapp.com/send?phone=+18573663242">Send a message</a>
            </article>
          </div>
          <form action="">
            <input type="text" name="name" placeholder='Your Full Name' required />
            <input type="email" name="email" placeholder='Your E-Mail' required />
            <textarea name="message" rows="7" placeholder='Your Message' required />
            <button class="btn btn-primary"  type="submit">Send Message</button>
          </form>
        </div>
      </section>
    )
}

export default Contact;
