import React from 'react';
import './style.css';

import Header from './components/header';
import Experience from './components/experience';
import Nav from './components/nav';
import About from './components/about';
import Footer from './components/footer';
import Contact from './components/contact';
import Portfolio from './components/portfolio';
import Services from './components/services';
import Testimonials from './components/testimonials';


const App = () => {
    return (
        <div className="app">
          <Header />
          <Nav />
          <About />
          <Experience />
          <Services />
          <Portfolio />
          <Testimonials />
          <Contact />
          <Footer />
        </div>
    )
}

export default App;
