import React from 'react';
import './style.css';

import {BiCheck} from 'react-icons/bi';

const Services = () => {
    return (
      <section id="services" className="services">
        <h5>Colaboro a mi comunidad</h5>
        <h2>Mis Servicios</h2>
        <div className="container services__container">
          <article className="service">
            <div className="service__head">
              <h3>Letreros y Toldos</h3>
            </div>
            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>Restaurantes.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Barberias.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Retail.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Spas.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Construcción.</p>
              </li>
            </ul>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Web Development</h3>
            </div>
            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>Consultoria web.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Diseño web.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Optimizacion de SEO (Google).</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Social Media.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Responsive Web Development.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>100% CUSTOM coded (NO WORDPRESS EVER!).</p>
              </li>
            </ul>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Estampados en ropa</h3>
            </div>
            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>Camisas.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Busos, abrigos y chaquetas.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Gorras.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Basos.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Car Wrapping.</p>
              </li>
            </ul>
          </article>
        </div>
    </section>
    )
}

export default Services;
