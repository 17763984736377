import React, {useState, useEffect} from 'react';
import './style.css';

import {AiOutlineHome} from 'react-icons/ai';
import {AiOutlineUser} from 'react-icons/ai';
import {BiBook} from 'react-icons/bi';
import {RiServiceLine} from 'react-icons/ri';
import { BiMessageSquareDetail } from 'react-icons/bi';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  
  const set = (x) => setActiveNav(x);
  const check = (x) => activeNav === x ? 'active' : '';

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const v = window.scrollY;

      if (v > 500 && v < 1000) {
        set('about');
      } else if (v > 1000 && v < 2000) {
        set('experience');
      } else if (v > 2000 && v < 3500) {
        set('services');
      } else if (v > 3501) {
        set('contact');
      } else {
        set('#');
      }
    });
  });

  return (
    <nav className="nav">
      <a href="#" className={check('#')} onClick={() => set('#')}><AiOutlineHome/></a>
      <a href="#about" className={check('about')} onClick={() => set('about')}><AiOutlineUser/></a>
      <a href="#experience" className={check('experience')} onClick={() => set('experience')}><BiBook/></a>
      <a href="#services" className={check('services')} onClick={() => set('services')}><RiServiceLine/></a>
      <a href="#contact" className={check('contact')} onClick={() => set('contact')}><BiMessageSquareDetail/></a>
    </nav>
  );
}

export default Nav;
