import React from 'react';
// import CV from '../../assets/cv.pdf';

const CTA = () => {
    return (
        <div className="cta">
          <a className='btn' href="#about" download>Quien Soy?</a>
          <a className='btn btn-primary' href="#contact">Hablemos!</a>
        </div>
    )
}

export default CTA;
