import React from 'react';
import './style.css';

import {Pagination } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



import AVATAR1 from '../../assets/avatar1.jpg';
import AVATAR2 from '../../assets/avatar2.jpg';
import AVATAR3 from '../../assets/avatar3.jpg';

const data = [
  {avatar: AVATAR1, name: 'Yofran Rodriguez', review: 'Excelente trabajo responsables atentos puntuales y el mejor diseño nunca antes visto lo recomiendo 100%'},
  {avatar: AVATAR2, name: 'Dario Pineda', review: 'Great service, friendly customer service, takes his time to work and make you happy! Lowest price of the competition, highly recommended!!! JOSE also Speak Spanish and Portuguese!'},
  {avatar: AVATAR3, name: 'Antonio Aguirre', review: 'Muy buen trabajo. Garantizado muy buenos precios y muy buena atenció[n] con  José cabrera muy buena Persona se Los recomiendo 100%'},
];


const Testimonial = (props) => {
  const {avatar, name, review} = props.item;
  return (
    <article className="testimonial">
      <div className="client__avatar">
        <img src={avatar} alt="client" />
      </div>
      <h5 className='client__name'>{name}</h5>
      <small className="client__review">
        {review}
      </small>
    </article>
  )
}

const Testimonials = () => {
    return (
      <section id="testimonials" className="testimonials">
      <h5>Le tengo gran cariño a mis clientes</h5>
      <h2>Testimonios</h2>
      <Swiper className="container testimonials__container" modules={[Pagination]} spaceBetween={40} slidesPerView={1} pagination={{clickable: true}}>
        {data.map((item) => <SwiperSlide><Testimonial key={item.id} item={item} /></SwiperSlide>)}
      </Swiper>
    </section>
    )
}

export default Testimonials;
